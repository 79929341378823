import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Where can I request an absentee ballot?",
  "description": null,
  "author": "Diego",
  "categories": ["general-election", "where", "faqs"],
  "date": "2020-08-28T00:00:00.000Z",
  "featured": false,
  "tags": [],
  "relatedArticles": ["2020-who-are-we-voting-for"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`On August 28, 2020, the Secretary of State's office built a `}<a parentName="p" {...{
        "href": "https://ballotrequest.sos.ga.gov/"
      }}>{`website`}</a>{` for people to request absentee ballots directly!`}</p>
    <p>{`“`}<em parentName="p">{`The website reduces the risk that election officials will `}<a parentName="em" {...{
          "href": "https://www.ajc.com/politics/investigation-concludes-fulton-failed-to-send-many-absentee-ballots/KS5XW5BP5RCHVHGSSWP7TWM26I/"
        }}>{`overlook`}</a>{` absentee ballot applications or incorrectly type in voters’ information, problems that surfaced before Georgia’s June 9 primary.`}</em>{`” - `}<a parentName="p" {...{
        "href": "https://www.ajc.com/politics/georgia-launches-paperless-absentee-ballot-request-website/ABFFNDFJQREU3ECQCO52RW5DW4/"
      }}>{`The Atlanta Journal-Constitution`}</a></p>
    <p><img parentName="p" {...{
        "src": "/images/ballotrequest-sos-ga-gov.png",
        "alt": "img"
      }}></img></p>
    <h2>{`Other Ways to Request`}</h2>
    <p>{`To request an absentee ballot, you must fill out a PDF form and either email or mail it to your County Board of Registrar Office.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`To download a copy of the absentee ballot form go `}<a parentName="p" {...{
            "href": "https://sos.ga.gov/admin/files/Absentee%20Ballot%20Fillable%20form%20820.pdf"
          }}>{`here`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To figure out where to mail it, go `}<a parentName="p" {...{
            "href": "https://elections.sos.ga.gov/Elections/countyregistrars.do"
          }}>{`here`}</a>{` and find your county email or mailing address.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`So for example, since I live in Fulton County, I would send an email to `}<a parentName="li" {...{
              "href": "mailto:elections.voterregistration@fultoncountyga.gov"
            }}>{`elections.voterregistration@fultoncountyga.gov`}</a>{`, with my filled out absentee ballot request form as an attachment, or I would mail it to 130 PEACHTREE ST SW SUITE 2186 ATLANTA, GA 30303-3460`}</li>
        </ul>
      </li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`To verify that they've recieved it, go to the Georgia Government’s `}<a parentName="li" {...{
          "href": "https://www.mvp.sos.ga.gov/"
        }}>{`My Voter Page`}</a>{`, login on the right hand side and click on `}<em parentName="li">{`Click here for Absentee Ballot status`}</em>{`. Keep in mind it does take a few weeks for them to process and update the website.`}</li>
    </ol>
    <p>{`Source: `}<a parentName="p" {...{
        "href": "https://sos.ga.gov/index.php/Elections/absentee_voting_in_georgia"
      }}>{`https://sos.ga.gov/index.php/Elections/absentee_voting_in_georgia`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      